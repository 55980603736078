// 获取bim路网数据接口
const dataListURL = `/gateway/hc-space/bimRoad/dataList`;
// 通过路网点名称获取bim路网数据接口
const dataListByNameURL = `/gateway/hc-space/bimRoad/dataListByName`;
// 导航接口步骤
const stepURL = `/gateway/d3m-base/maze/step`;
//新的演示接口
const bimRoadUrl = `/gateway/d3m-base/maze/poi`;
//类型接口
const poiType = `/gateway/d3m-base/maze/poiType`;
//埋点数据
const saveOrUpdateDataEmbeddingPoint = `/gateway/blade-system/dataEmbeddingPoint/saveOrUpdateDataEmbeddingPoint`;
//根据访客编码返回相应参数
const getParamByVisitorId = `/gateway/blade-system/visitorRegistration/getParamByVisitorId`;
//根据访客是否点击路线引导指引
const checkIsClickUrl = `/gateway/blade-system/visitorRegistration/checkIsClick`;
//点击记录路线引导
const clickUrl = `/gateway/blade-system/visitorRegistration/click`;
export {
  bimRoadUrl,
  checkIsClickUrl,
  clickUrl,
  dataListURL,
  dataListByNameURL,
  stepURL,
  poiType,
  saveOrUpdateDataEmbeddingPoint,
  getParamByVisitorId,
};
